import {
  TypographyComponent,
  TypographySize,
  TypographyWeight,
} from '@vaa-component-lib/component.atom.typography';
import classnames from 'classnames';
import styles from './article-card.component.module.less';
import Link from 'next/link';

export interface ArticleCardProps {
  imagePath: string;
  lastUpdated: string;
  path: string;
  title: string;
  className?: string;
}

export default function ArticleCard(props: ArticleCardProps) {
  const { title, path, imagePath, className = '' } = props;

  return (
    <Link
      href={path}
      className={classnames(styles['article-card'], className)}
      role="link"
      aria-label={`${title}`}
    >
      <div
        data-testid="article-card-image"
        className={styles['article-card__background']}
        style={{ backgroundImage: `url("${imagePath}")` }}
        aria-hidden="true"
      >
        <span className={styles['article-card__sr-only']}>{title}</span>
      </div>
      <article className={styles['article-card__content']} role="article">
        <div className={styles['article-card__title']}>
          <TypographyComponent
            element="h3"
            size={TypographySize.Xlg}
            weight={TypographyWeight.Medium}
          >
            {title}
          </TypographyComponent>
        </div>
      </article>
    </Link>
  );
}
